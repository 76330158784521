<template>
  <div>
    <PlanetChart />
  </div>
</template>

<script>
import PlanetChart from './../../components/chartjs/dona';

export default {
  name: 'piechart',
  components: {
    PlanetChart,
  },
};
</script>
